import React from "react";

// Components
import { useNavigate } from "react-router-dom";
import { useGetWithoutLoginServicesByRoleQuery } from "../../../../Redux/features/Service/ServiceApi";
import VarientAnimation from "../../../../assets/utilities/Animation/VarientAnimation";
import { ServerErrorMessage } from "../../../ui/error/ServerErrorMessage";
import ServicesPromoCard from "./ServicesPromoCard/ServicesPromoCard";

const ServicesPromo = () => {
    const navigate = useNavigate();

    const loaderArr = [1, 2, 3, 4];
    const { data, isLoading, error } = useGetWithoutLoginServicesByRoleQuery({
        page: 1,
        limit: 12,
        role: "kazi",
    });

    let content;
    if (isLoading) {
        content = (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-16">
                {loaderArr.map(arr => (
                    <div key={arr} className="animate-pulse">
                        <div className="h-40 bg-slate-100"></div>
                        <div className="h-4 mt-4 bg-slate-100"></div>
                        <div className="h-4 mt-2 bg-slate-100"></div>
                    </div>
                ))}
            </div>
        );
    } else if (error) {
        content = (
            <div className="flex justify-center my-24">
                <ServerErrorMessage />
            </div>
        );
    } else if (!error && data?.services?.length === 0) {
        content = <div className="my-24 w-full flex justify-center items-center text-xl font-Poppins text-gray-400">No Kazi services found!</div>;
    } else if (!error && data?.services?.length > 0) {
        content = (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-8 md:mt-14">
                {data?.services?.map(service => {
                    return <ServicesPromoCard key={service?._id} {...{ service }} />;
                })}
            </div>
        );
    }

    return (
        <div>
            <div className="w-full">
                <div className="md:w-[700px] lg:w-[1200px] mx-auto pt-12 md:pt-20 lg:pt-28 px-6 lg:px-0">
                    <VarientAnimation direction="right" delay={0.2}>
                        <h1 className="text-[27px] lg:text-[32px] font-fira font-semibold w-full pb-[5px]">Make it Real with Shongshari.</h1>
                    </VarientAnimation>
                    <VarientAnimation direction="right" delay={0.4}>
                        <p className="text-[16px] font-Poppins mt-1 md:mt-8 w-full">Get some Inspirations from the services.</p>
                    </VarientAnimation>
                    <div>{content}</div>
                    <div className="text-center pt-8 md:pt-14">
                        <button
                            onClick={() => navigate("/allServices")}
                            className="px-[15px] py-[5px] text-[14px] lg:text-[16px] special_profile_button font-Nunito font-medium"
                        >
                            View All Services
                        </button>
                    </div>
                    <hr className="mt-12 md:mt-20 lg:mt-28 border w-[328px] md:w-full" />
                </div>
            </div>
        </div>
    );
};

export default ServicesPromo;
