import React from "react";
import { BottomNav } from "../BottomNav";
import { AccountSettingMov } from "./AccountSettingMov";
import { MobileBackButton } from "../../../../components/shared/Components/MobileBackButton";

export const Setting = () => {
    return (
        <div className="max-w-[1024px] mx-auto">
            <MobileBackButton name="Setting" />

            {/* menus  */}
            <AccountSettingMov></AccountSettingMov>
            <div className="h-24"></div>

            {/* bottom navigation  */}
            <BottomNav></BottomNav>
        </div>
    );
};
