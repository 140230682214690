import { Route } from "react-router-dom";
import kaziPublicRoutes from "./kaziPublicRoutes";

const kaziPublicRoutesRenderer = () => {
    return kaziPublicRoutes.map(route => {
        return <Route path={route.path} element={route.element} key={route.id} />;
    });
};

export default kaziPublicRoutesRenderer;
