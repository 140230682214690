// Configuration
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CompletedOrder } from "../../../shared/Components/LawyerAgentKaziService/CompletedOrder/CompletedOrder";

// Components
import { PendingOrder } from "../../../shared/Components/LawyerAgentKaziService/PendingOrder/PendingOrder";
import RunningOrder from "../../../shared/Components/LawyerAgentKaziService/RunningOrder/RunningOrder";
import { ServicesContainer } from "../../../shared/Components/LawyerAgentKaziService/servicesContainer/ServicesContainer";

const KaziServicesPage = () => {
    const [pendingOrder, setPendingOrder] = useState(true);
    const [runningOrder, setRunningOrder] = useState(true);
    const [completedOrder, setCompletedOrder] = useState(true);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);
    return (
        <div className="lg:max-w-[1000px] xl:max-w-[1200px] mx-auto">
            <div className="w-11/12 mx-auto xl:w-full">
                <div className="mt-[70px]">
                    <ServicesContainer />
                    <div className="my-12 md:my-16 lg:my-28 flex justify-center">
                        <Link
                            className="special_profile_button "
                            to={"/kaziProfile/kaziAddServiceForm"}
                        >
                            ADD NEW SERVICE
                        </Link>
                    </div>
                </div>
                {pendingOrder && <PendingOrder setPendingOrder={setPendingOrder} />}
                {runningOrder && <RunningOrder setRunningOrder={setRunningOrder} />}
                {completedOrder && <CompletedOrder setCompletedOrder={setCompletedOrder} />}
            </div>
        </div>
    );
};

export default KaziServicesPage;
