// configuration
import React from "react";
import { useNavigate } from "react-router-dom";

// components
import customerSupport from "../../../../assets/images/AboutUs/customer-support.png";
import VarientAnimation from "../../../../assets/utilities/Animation/VarientAnimation";

const KnowMore = () => {
    const navigate = useNavigate();
    return (
        <div className="bg-[#dcdcdc] pt-5 pb-5 lg:pb-0">
            <div className="max-w-[1200px] mx-auto flex flex-col-reverse lg:flex-row justify-between items-center gap-x-8 px-6 lg:px-0">
                <div>
                    <p className="text-2xl md:text-3xl lg:text-4xl lg:whitespace-nowrap mb-8 font-medium lg:font-normal text-center lg:text-left">
                        Want to know more about Shongshari and its services?
                    </p>
                    <div className="flex justify-center lg:justify-start items-center">
                        <button onClick={() => navigate("/contact")} className="special_profile_button mr-4">
                            Contact Us
                        </button>
                        <button className="special_profile_button_hover">+88 0484 8484 83848</button>
                    </div>
                </div>
                <div className="overflow-hidden">
                    <VarientAnimation direction="up" delay={0.3}>
                        <img className="w-2/3 lg:w-full mx-auto mb-2 md:mb-4 lg:mb-0" src={customerSupport} alt="support" />
                    </VarientAnimation>
                </div>
            </div>
        </div>
    );
};

export default KnowMore;
