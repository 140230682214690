// Configuration
import React from "react";
import { useNavigate } from "react-router-dom";

// Third party package
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// components
import isLoggedIn from "../../../../Helper/hooks/checkLoggerPersestency/isLoggedIn";
import banner1 from "../../../../assets/images/banner/kaziBanner1.jpg";
import banner2 from "../../../../assets/images/banner/kaziBanner2.jpg";
import banner3 from "../../../../assets/images/banner/kaziBanner3.jpg";

const Banner = () => {
  const navigate = useNavigate();

  const user = isLoggedIn();

  const bannerImg = [
    {
      id: 1,
      img: banner1,
    },
    {
      id: 2,
      img: banner2,
    },
    {
      id: 3,
      img: banner3,
    },
  ];
  return (
    <div>
      <div className="w-full h-[60vh] md:h-[80vh] lg:h-[100vh] relative">
        <Swiper
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
        >
          {bannerImg.map((banner) => {
            return (
              <SwiperSlide key={banner.id}>
                <img
                  className="w-full h-[60vh] md:h-[80vh] lg:h-[100vh]"
                  src={banner.img}
                  alt=""
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="w-full h-[60vh] md:h-[80vh] lg:h-[100vh] absolute top-0 z-10 flex items-center justify-start bg-black bg-opacity-40">
          <div className="md:w-[700px] lg:w-[1200px] mx-auto px-6 lg:px-0">
            <h1 className="text-3xl md:text-5xl lg:text-7xl font-fira font-semibold text-white">
              Welcome To <br /> Shongshari Kazi
            </h1>
            <div>
              <ul className="text-white list-disc pl-6 md:pl-10 text-base font-fira my-2 md:my-5">
                <li>Register/Login To Kazi Feature</li>
                <li>Browse Kazi Profile</li>
                <li>Chat Request To 5 Kazis per Day</li>
              </ul>
            </div>
            <div className="mt-4 flex gap-2 md:gap-5">
              <button
                onClick={() => navigate("/login")}
                className={`special_profile_button ${
                  user && "hidden"
                }`}
              >
                Create Account
              </button>
              <button className="special_profile_button_hover bg-transparent text-white">
                Earn More Money
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
