// Configuration
import React from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import kaziRouteRenderer from "./Kazi/privateRoutes/kaziRouteRenderer";
import kaziPublicRoutesRenderer from "./Kazi/publicRoutes/kaziPublicRouteRenderer";
import chatPrivateRouteRenderer from "./Kazi/ChatPrivateRoutes/chatPrivateRouteRenderer";

// Components

const MasterRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* KAZI PRIVATE ROUTES */}
        {kaziRouteRenderer()}
        {/* KAZI PUBLIC ROUTES */}
        {kaziPublicRoutesRenderer()}
        {/* Chat private route */}
        {chatPrivateRouteRenderer()}
      </Routes>
    </BrowserRouter>
  );
};

export default MasterRoute;
