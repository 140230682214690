import FindAgentPage from "../../../components/pages/Agent/FindAgent/FindAgentPage";
import { FindRecentAgent } from "../../../components/pages/Agent/FindAgent/FindRecentAgent";
import AllServicesPage from "../../../components/pages/AllServicesPage/AllServicesPage";
import { DynamicProfilePage } from "../../../components/pages/DynamicProfilePage/DynamicProfilePage";
import { DynamicSingleServiceContainer } from "../../../components/pages/DynamicProfilePage/DynamicSingleServiceContainer/DynamicSingleServiceContainer";
import { MobileDynamicProfile } from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfile";
import { MobileDynamicEducationalDetails } from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicEducationalDetails";
import MobileDynamicFamilyDetails from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicFamilyDetails";
import { MobileDynamicOtherDetails } from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicOtherDetails";
import { MobileDynamicPersonalDetails } from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicPersonalDetails";
import { MobileDynamicPhysicalDetails } from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicPhysicalDetails";
import { MobileDynamicProfessionalDetails } from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicProfessionalDetails";
import MobileDynamicProfileActivity from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicProfileActivity";
import MobileDynamicProfileConnections from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicProfileConnections";
import MobileDynamicSiblingsDetails from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicProfileDetails/MobileDynamicSiblingsDetails";
import MobileDynamicServicesContainer from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileDynamicServicesContainer/MobileDynamicServicesContainer";
import DynamicSingleService from "../../../components/pages/DynamicSingleService/DynamicSingleService";
import FindAlawyerPage from "../../../components/pages/FindAlawyer/FindAlawyerPage";
import { FindRecentLawyer } from "../../../components/pages/FindAlawyer/FindRecentLawyer";
import FindKaziPage from "../../../components/pages/Kazi/FindKazi/FindKaziPage";
import { FindRecentKazi } from "../../../components/pages/Kazi/FindKazi/FindRecentKazi";
import KaziActivity from "../../../components/pages/Kazi/KaziActivity/KaziActivity";
import KaziReviewsContainer from "../../../components/pages/Kazi/KaziReview/KaziReviewsContainer";
import KaziAddServiceForm from "../../../components/pages/Kazi/KaziServices/AddServiceForm/KaziAddServiceForm";
import KaziServicesPage from "../../../components/pages/Kazi/KaziServices/KaziServicesPages";
import { KaziProfile } from "../../../components/pages/Kazi/Profile/KaziProfile";
import { KaziProfileContainer } from "../../../components/pages/Kazi/Profile/profileContainer/KaziProfileContainer";
import Packages from "../../../components/pages/packages/Packages";
import { ServiceEdit } from "../../../components/shared/Components/LawyerAgentKaziService/ServiceEdit/ServiceEdit";
import SingleServicesContainer from "../../../components/shared/Components/LawyerAgentKaziService/SingleServices/SingleServicesContainer";
import { PaymentSuccess } from "../../../components/shared/PaymentSuccess/PaymentSuccess";
import EducationalCertificateWeb from "../../../components/SingleProfilesUser/CertificateSection/EducationalCertificateWeb";
import PersonalCertificateWeb from "../../../components/SingleProfilesUser/CertificateSection/PersonalCertificateWeb";
import EditEducationalInfo from "../../../components/SingleProfilesUser/profileSection/EditprofileSection/EditEducationalInfo";
import EditOthersInfo from "../../../components/SingleProfilesUser/profileSection/EditprofileSection/EditOthersInfo";
import EditPersonalInfo from "../../../components/SingleProfilesUser/profileSection/EditprofileSection/EditPersonalInfo";
import EditPhysicalInfo from "../../../components/SingleProfilesUser/profileSection/EditprofileSection/EditPhysicalInfo";
import EditProfesionalInfo from "../../../components/SingleProfilesUser/profileSection/EditprofileSection/EditProfesionalInfo";
import ProfileDetails from "../../../components/SingleProfilesUser/profileSection/ProfileDetails";
import MobileDynamicSingleServiceContainer from "../../../Wrapper/HomePage/mobileversion/MobileDynamicSingleServiceContainer/MobileDynamicSingleServiceContainer";
import SuggestedAgentForMobile from "../../../Wrapper/HomePage/mobileversion/MobileFindLawyerAgentKazi/SuggestedAgentForMobile/SuggestedAgentForMobile";
import SuggestedKaziForMobile from "../../../Wrapper/HomePage/mobileversion/MobileFindLawyerAgentKazi/SuggestedKaziForMobile/SuggestedKaziForMobile";
import SuggestedLawyerForMobile from "../../../Wrapper/HomePage/mobileversion/MobileFindLawyerAgentKazi/SuggestedLawyerForMobile/SuggestedLawyerForMobile";
import MobileKaziActivity from "../../../Wrapper/HomePage/mobileversion/MobileKazi/MobileKaziActivity/MobileKaziActivity";
import MobileKaziAddServiceForm from "../../../Wrapper/HomePage/mobileversion/MobileKazi/MobileKaziAddServiceForm/MobileKaziAddServiceForm";
import MobileKaziServicesPage from "../../../Wrapper/HomePage/mobileversion/MobileKazi/MobileKaziServicesPage/MobileKaziServicesPage";
import MobileLawyerAgentKaziReviewsContainer from "../../../Wrapper/HomePage/mobileversion/MobileLawyerAgentKaziReviewsContainer/MobileLawyerAgentKaziReviewsContainer";
import MobileProfServiceEdit from "../../../Wrapper/HomePage/mobileversion/MobileProfServiceEdit/MobileProfServiceEdit";
import MobileProfSingleServicesContainer from "../../../Wrapper/HomePage/mobileversion/MobileProfSingleServicesContainer/MobileProfSingleServicesContainer";
import { GalleryContainerForMobile } from "../../../Wrapper/HomePage/mobileversion/PhotoGallery/GalleryContainerForMobile";
import { Setting } from "../../../Wrapper/HomePage/mobileversion/setting/Setting";
import MobileSingleProfilesUser from "../../../components/SingleProfilesUser/MobileSingleProfilesUser/MobileSingleProfilesUser";
import { PersonalDetailsMobile } from "../../../components/SingleProfilesUser/MobileSingleProfilesUser/PersonalDetailsMobile";
import { MobilePersonalDetails } from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobilePersonalDetails";
import { MobileProfessionalDetails } from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileProfessionalDetails";
import { MobileEducationalDetails } from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileEducationalDetails";
import { MobilePhysicalDetails } from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobilePhysicalDetails";
import { MobileOthersDetails } from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileOthersDetails";
import MobileFamilyDetails from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileFamilyDetails";
import MobileSiblingsDetails from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileSiblingsDetails";
import EditFamilyInfo from "../../../components/SingleProfilesUser/profileSection/EditprofileSection/EditFamilyInfo";
import EditSiblingsInfo from "../../../components/SingleProfilesUser/profileSection/EditprofileSection/EditSiblingsInfo";
import MobileProfessionalInfoEdit from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileEditProfile/MobileProfessionalInfoEdit";
import MobileUserConnections from "../../../components/pages/DynamicProfilePage/MobileDynamicProfile/MobileUserConnections/MobileUserConnections";
import { CertificateMov } from "../../../Wrapper/HomePage/mobileversion/CertificateForMobile/CertificateMov";
import { Like } from "../../../Wrapper/HomePage/mobileversion/Like";
import { NotificationForMobile } from "../../../components/SingleProfilesUser/Notification/NotificationForMobile";
import { DesktopNotification } from "../../../components/shared/Notification/DesktopNotification";

const kaziPrivateRoutes = [
  {
    id: 1,
    path: "/kaziProfile",
    element: <KaziProfile />,
    nestedRoutes: [
      {
        id: 1,
        path: "kaziServices",
        element: <KaziServicesPage />,
      },
      {
        id: 2,
        path: "review",
        element: <KaziReviewsContainer />,
      },
      {
        id: 3,
        path: "kaziAddServiceForm",
        element: <KaziAddServiceForm />,
      },
      {
        id: 4,
        path: "kaziSingleServiceDetails/:id",
        element: <SingleServicesContainer />,
      },
      {
        id: 5,
        path: "activity",
        element: <KaziActivity />,
      },
      {
        id: 6,
        path: "profile",
        element: <KaziProfileContainer />,
        nestedRoutes: [
          {
            id: 1,
            path: "",
            element: <ProfileDetails />,
          },
          {
            id: 2,
            path: "edit",
            nestedRoutes: [
              {
                id: 1,
                path: "personalinfo/:id",
                element: <EditPersonalInfo />,
              },
              {
                id: 2,
                path: "profesionalinfo/:id",
                element: <EditProfesionalInfo />,
              },
              {
                id: 3,
                path: "educationalinfo/:id",
                element: <EditEducationalInfo />,
              },
              {
                id: 4,
                path: "physicalinfo/:id",
                element: <EditPhysicalInfo />,
              },
              {
                id: 5,
                path: "othersinfo/:id",
                element: <EditOthersInfo />,
              },
            ],
          },
        ],
      },
      {
        id: 7,
        path: "serviceEdit/:id",
        element: <ServiceEdit />,
      },
    ],
  },
  {
    id: 2,
    path: "/mobileKaziServices",
    element: <MobileKaziServicesPage />,
  },
  {
    id: 3,
    path: "/kaziAddServiceFormMov",
    element: <MobileKaziAddServiceForm />,
  },
  {
    id: 4,
    path: "kaziSingleServiceDetailsMov/:id",
    element: <MobileProfSingleServicesContainer />,
  },
  {
    id: 5,
    path: "/kaziActivityMov",
    element: <MobileKaziActivity />,
  },
  {
    id: 6,
    path: "/kaziReviewsMov",
    element: <MobileLawyerAgentKaziReviewsContainer />,
  },
  {
    id: 7,
    path: "serviceEditMov/:id",
    element: <MobileProfServiceEdit />,
  },
  {
    id: 8,
    path: "/singleService/:id",
    element: <DynamicSingleService />,
  },
  {
    id: 9,
    path: "/allServices",
    element: <AllServicesPage />,
  },
  {
    id: 10,
    path: "/packages",
    element: <Packages />,
  },
  {
    id: 11,
    path: "/findLawyers",
    element: <FindAlawyerPage />,
    nestedRoutes: [
      {
        id: 1,
        path: "recent",
        element: <FindRecentLawyer />,
      },
    ],
  },
  {
    id: 12,
    path: "/profile/:id",
    element: <DynamicProfilePage />,
  },
  {
    id: 13,
    path: "/service/:id",
    element: <DynamicSingleServiceContainer />,
  },
  {
    id: 14,
    path: "/kazi",
    element: <FindKaziPage />,
    nestedRoutes: [
      {
        id: 1,
        path: "recent",
        element: <FindRecentKazi role="kazi" />,
      },
    ],
  },
  {
    id: 15,
    path: "agent",
    element: <FindAgentPage />,
    nestedRoutes: [
      {
        id: 1,
        path: "recent",
        element: <FindRecentAgent />,
      },
    ],
  },
  {
    id: 16,
    path: "/personalCertificate",
    element: <PersonalCertificateWeb />,
  },
  {
    id: 17,
    path: "/professionalCertificate",
    element: <EducationalCertificateWeb title="prof" />,
  },
  {
    id: 18,
    path: "/educationalCertificate",
    element: <EducationalCertificateWeb title="edu" />,
  },
  {
    id: 19,
    path: "/marriageCertificate",
    element: <EducationalCertificateWeb title="marriage" />,
  },
  {
    id: 20,
    path: "/payment_Success",
    element: <PaymentSuccess />,
  },
  {
    id: 21,
    path: "/setting",
    element: <Setting />,
  },
  {
    id: 22,
    path: "/findLawyerMov",
    element: <SuggestedLawyerForMobile />,
  },
  {
    id: 23,
    path: "/findKaziMov",
    element: <SuggestedKaziForMobile role="kazi" />,
  },
  {
    id: 24,
    path: "/findAgentMov",
    element: <SuggestedAgentForMobile />,
  },
  {
    id: 25,
    path: "/dynamicConnectionsMov/:id",
    element: <MobileDynamicProfileConnections />,
  },
  {
    id: 26,
    path: "/dynamicActivityMov/:id",
    element: <MobileDynamicProfileActivity />,
  },
  {
    id: 27,
    path: "/dynamicGalleryMov/:id",
    element: <GalleryContainerForMobile />,
  },
  {
    id: 28,
    path: "/mobileprofile/:id",
    element: <MobileDynamicProfile />,
  },
  {
    id: 29,
    path: "/dynamicServicesMov/:id",
    element: <MobileDynamicServicesContainer />,
  },
  {
    id: 30,
    path: "/serviceMov/:id",
    element: <MobileDynamicSingleServiceContainer />,
  },
  {
    id: 31,
    path: "/personalProfileDynamic/:id",
    element: <MobileDynamicPersonalDetails />,
  },
  {
    id: 32,
    path: "/professionalProfileDynamic/:id",
    element: <MobileDynamicProfessionalDetails />,
  },
  {
    id: 33,
    path: "/educationalProfileDynamic/:id",
    element: <MobileDynamicEducationalDetails />,
  },
  {
    id: 34,
    path: "/physicalProfileDynamic/:id",
    element: <MobileDynamicPhysicalDetails />,
  },
  {
    id: 35,
    path: "/familyProfileDynamic/:id",
    element: <MobileDynamicFamilyDetails />,
  },
  {
    id: 36,
    path: "/siblingsProfileDynamic/:id",
    element: <MobileDynamicSiblingsDetails />,
  },
  {
    id: 37,
    path: "/otherProfileDynamic/:id",
    element: <MobileDynamicOtherDetails />,
  },
  {
    id: 39,
    path: "/mobileuserprofile",
    element: <MobileSingleProfilesUser />,
    nestedRoutes: [
      {
        id: 1,
        path: "personaldetailsmov",
        element: <PersonalDetailsMobile />,
      },
    ],
  },
  {
    id: 40,
    path: "/personalprofile/:id",
    element: <MobilePersonalDetails />,
  },
  {
    id: 41,
    path: "/professionalprofile/:id",
    element: <MobileProfessionalDetails />,
  },
  {
    id: 42,
    path: "/educationalprofile/:id",
    element: <MobileEducationalDetails />,
  },
  {
    id: 43,
    path: "/physicalprofile/:id",
    element: <MobilePhysicalDetails />,
  },
  {
    id: 44,
    path: "/otherprofile/:id",
    element: <MobileOthersDetails />,
  },
  {
    id: 45,
    path: "/familyprofile/:id",
    element: <MobileFamilyDetails />,
  },
  {
    id: 46,
    path: "/siblingsprofile/:id",
    element: <MobileSiblingsDetails />,
  },
  {
    id: 47,
    path: "/familyInfoEdit/:id",
    element: <EditFamilyInfo />,
  },
  {
    id: 48,
    path: "/siblingsInfoEdit/:id",
    element: <EditSiblingsInfo />,
  },
  {
    id: 49,
    path: "/othersInfoEdit/:id",
    element: <EditOthersInfo />,
  },
  {
    id: 50,
    path: "/physicalInfoEdit/:id",
    element: <EditPhysicalInfo />,
  },
  {
    id: 51,
    path: "/educationalInfoEdit/:id",
    element: <EditEducationalInfo />,
  },
  {
    id: 52,
    path: "/professionalInfoEdit/:id",
    element: <MobileProfessionalInfoEdit />,
  },
  {
    id: 53,
    path: "/personalInfoEdit/:id",
    element: <EditPersonalInfo />,
  },
  {
    id: 54,
    path: "/galleryMov/:id",
    element: <GalleryContainerForMobile />,
  },
  {
    id: 55,
    path: "/connections/:id",
    element: <MobileUserConnections />,
  },
  {
    id: 56,
    path: "/certificatemov",
    element: <CertificateMov />,
  },
  {
    id: 57,
    path: "/like",
    element: <Like />,
  },
  {
    id: 58,
    path: "/desktopNotification/:id",
    element: <DesktopNotification />,
  },
  {
    id: 59,
    path: "/notifications",
    element: <NotificationForMobile />,
  },
];

export default kaziPrivateRoutes;
